<template>
  <div class="home">
    <div v-if="$store.getters.userInfo.tenantId===2||$store.getters.userInfo.tenantId===5" style="text-align:center;color:#0096ff;display: flex;height:80vh;flex-direction: column;justify-content: center;">
      <h1>欢迎使用CMS后台管理系统</h1>
      <br>
      <span style="line-height:40px;">
        技术支持：江苏希创信息工程有限公司<br>
        联系电话：0511-80857677<br>
        电子邮箱：121341976@qq.com
      </span>
    </div>
    <div v-else>
      <img alt="Vue logo" src="../assets/logo.png">
      <HelloWorld msg="Welcome to Your Vue.js App" />
      <p>
        <br>
        <el-button type="button" @click="MessageBoxTest">对话框测试</el-button>
        <el-button type="button" @click="confirmBoxTest">确认框测试</el-button>
      </p>
      <div style="height:900px">
        <p>
          测试滚动条
        </p>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  components: {
    HelloWorld
  },
  methods: {
    MessageBoxTest() {
      this.$alert('无权限访问该资源！')
    },
    confirmBoxTest() {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '删除成功!',
          position: 'bottom-right'
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除',
          position: 'bottom-right'
        })
      })
    }
  }
}
</script>
